<div class="zero">
    <div class="band">
        <h2>Nuestra Empresa</h2>
    </div>
    <div class="img"></div>
    <div class="band"></div>
    <div class="desc bf">
        <br>
        <b>ACPM S.A.S. </b> es una compañía consolidada y debidamente estructurada, cuya trayectoria en el mercado la ha
        posicionado como una de las mejores y más confiables en el suministro de combustible y servicios conexos.

        Desde su fecha de constitución (04 de febrero de 1994) se ha caracterizado por su idoneidad, excelencia y
        responsabilidad frente a los clientes.

        Se cuenta con la <b>Resolución No. 124265 de 2007 del Ministerio de Minas y Energía</b> y la <b>Resolución No.
            316 de 2014
            del Ministerio de Transporte</b>, que nos habilitan para fungir como Distribuidores Minoristas.

    </div>
</div>
<div class="disp row no-gutters">
    <div class="col-12 col-sm-4 item">
        <img src="/assets/about/zero/mision.svg" alt="">
        <h3 class="title">Misión</h3>
        <div class="desc centrado-h">
            Construir una sólida trayectoria en la
            mayor red de abastecimiento de combustible
            basada en una relacion estable
            duradera, con altos niveles de servicio,
            calidad y cantidad exacta, en
            beneficio de nuestros clientes.
        </div>
    </div>
    <div class="col-4 d-none d-sm-flex flechas">
        <img class="centrado" src="/assets/about/zero/flechas.svg" alt="">
    </div>
    <div class="col-12 col-sm-4 item">
        <img src="/assets/about/zero/vision.svg" alt="">
        <h3 class="title">Visión</h3>
        <div class="desc centrado-h">
            Convertirnos en el principal comercializador
            de combustibles a nivel nacional,
            acordes con el desarrollo del país,
            generando progreso, desarrollo y estabilidad
            a nuestros clientes y
            accionistas.
        </div>
    </div>
    <div class="col-12 item down">
        <img src="/assets/about/zero/talento.svg" alt="">
        <h3 class="title">Talento Humano</h3>
        <div class="desc centrado-h">
            Nuestro personal está capacitado y
            entrenado por ORGANIZACION
            TERPEL SA, lo cual permite entregar
            toda nuestra experiencia y capacidad
            de servicio a nuestros clientes, lo cual
            conlleva a tener valores como son
            confianza
        </div>

    </div>
</div>
<div class="section">
    <h2 class="title">Nuestros Vehículos</h2>
    <div class="desc">
        Contamos con una flota de vehículos debidamente actualizados, último modelo, que ofrecen total protección a los
        productos transportados, garantizando así la entrega óptima y confiable. Aplicamos en el transporte los más
        estrictos controles de seguridad exigidos tanto por la normatividad vigente como por el Distribuidor Mayorista.

        Para plena seguridad en el recorrido, cada uno de los vehículos cuenta con un sistema satelital en el que el
        cliente puede efectuar el respectivo monitoreo. De tal forma, se supervisará todo momento desde el cargue en la
        planta de abastecimiento hasta su descargue final.

        Con el propósito de precisar el número de galones suministrados, poseemos contadores eléctricos que constatan la
        exactitud de las cantidades entregadas. Aunado a lo anterior, todos los tanques cuentan con la tabla de aforo.

    </div>
</div>
<div class="section">
    <h2 class="title">Experiencia y Respaldo</h2>
    <div class="desc">
        Con sus 26 años de existencia, ACPM S.A.S. se ha consolidado en el mercado como una empresa confiable. Contamos
        con el respaldo de nuestro proveedor mayorista, acogiéndonos y aplicando debidamente tanto los requisitos
        normativos como los corporativos.

        Todo lo anterior nos permite tener eficiencia operacional en el territorio nacional. Gracias a nuestra
        experiencia, atendemos medianas y grandes empresas que certifican la calidad de nuestro servicio.

    </div>
</div>
<div class="fourth">
    <div class="band">
        <h2>Alianza Estrategica</h2>
    </div>
    <div class="show row">
        <div class="col">
            <img class="centrado-h" src="/assets/about/fourth/ACPM.png" alt="">
        </div>
        <div class="col">
            <img class="centrado-h" src="/assets/about/fourth/terpel.png" alt="">
        </div>
    </div>
    <div class="desc">
        Ponemos al servicio de su compañía toda la experiencia y asesoría de ACPM S.A.S. y ORGANIZACIÓN
        TERPEL en todos los temas relacionados con la gestión de combustibles.
    </div>
</div>