import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  functions = [];

  constructor() {
    window.onscroll = () => this.scrollFunction();
  }

  scrollFunction() {
    this.functions.forEach(fun => {
      fun();
    });
  }

  addFunction(fun) {
    this.functions.push(fun);
  }
}
