import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../services/scroll.service'
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  selected;
  constructor(scroll: ScrollService, router: Router) {
    scroll.addFunction(() => this.scrollFunction());
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selected = event.url;
        console.log(this.selected);
      }
    })
  }
  scrollFunction() {
    console.log(this.selected)
    if (document.body.scrollTop > 600 || document.documentElement.scrollTop > 600 || this.selected != "/home") {
      document.getElementById("navbar").classList.add("small")
    } else {
      document.getElementById("navbar").classList.remove("small")
    }
  }

  ngOnInit(): void {
  }

}
