import { Component, OnInit } from '@angular/core';
import simpleParallax from 'simple-parallax-js';
import { getLocaleDateFormat } from '@angular/common';
import { ScrollService } from '../services/scroll.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  headerText = "VENTA Y SUMINISTRO DE COMBUSTIBLES \n A NIVEL NACIONAL"
  contactForm
  second1 = [
    {
      img: "home/second/calibracion.svg",
      text: "Calibracion de Equipos"
    },
    {
      img: "home/second/comodato.svg",
      text: "Equipos en Comodato"
    },
    {
      img: "home/second/lavado.svg",
      text: "Lavado de Tanques"
    }
  ]

  second2 = [
    {
      img: "home/second/mantenimiento.svg",
      text: "Mantenimiento de Equipos"
    },
    {
      img: "home/second/rastreo.svg",
      text: "Seguimiento Satelital"
    },
    {
      img: "home/second/soporte.svg",
      text: "Soporte Tecnico"
    }
  ]
  third = [
    {
      img: "home/third/2000.png",
      text: "Turbo - Capacidad 2000 GAL"
    },
    {
      img: "home/third/3200.png",
      text: "Sencillo - Capacidad 3200 GAL"
    },
    {
      img: "home/third/5600.png",
      text: "Doble Torque - Capacidad 5600 GAL"
    },
    {
      img: "home/third/6400.png",
      text: "Mini Mula - Capacidad 6400 GAL"
    }
  ]
  fourth1 = [
    {
      img: "home/fourth/Mantenimiento de equipos.svg",
      text: "Mantenimiento de Equipos",
      more: "Contamos con técnicos en equipos electrónicos análogos o digitales para el suministro de combustible"
    },
    {
      img: "home/fourth/lavado de tanque.svg",
      text: "Lavado de tanque",
      more: "Aditivo detergente y personal altamente capacitado en trabajo confinado"
    }
  ]
  fourth2 = [
    {
      img: "home/fourth/Calibracion de equipos.svg",
      text: "Calibración de equipos",
      more: "Se efectuarán visitas periódicas con el fin de mantener los equipos dentro de los niveles permitidos de entrega."
    }
  ]
  seventh1 = [
    {
      img: "home/seventh/express.png",
      text: "ACPM Express",
      more: "ACPM S.A.S pone al servicio de sus clientes su programa de ACPM Express, direccionado a la recepción, análisis y recolección de muestras, para de esta manera controlar y mejorar la eficiencia de la operación en la gestión de combustibles dando como resultado una mayor productividad."
    },
    {
      img: "home/seventh/fo2.jpg",
      text: "Soporte Técnico",
      more: "Sumado como valor agregado tendremos disposición de todo el alcance de SOPORTE TÉCNICO sin ningún costo, en diferentes lineamientos como: CAPACITACIÓN TÉCNICA (A Operadores, mecánicos y Jefes de Mantenimiento)"
    }
  ]
  seventh2 = [
    {
      img: "home/seventh/fo.png",
      text: "Equipos en Comodato",
      more: "Como herramienta de trabajo tendremos la posibilidad de entregara nuestros clientes equipos en comodato tanto de almacenamiento, filtrado y suministro de acuerdo a sus necesidades"
    }
  ]
  constructor(scroll: ScrollService, fb: FormBuilder, private contact: ContactService) {
    scroll.addFunction(this.scrollFunction);
    this.contactForm = fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      msm: ['', Validators.required],
    })
  }
  scrollFunction() {
    let head = document.getElementById("heade")
    if (document.documentElement.scrollTop < 700) {
      head.style.height = `${700 - document.documentElement.scrollTop}px`
    }
    else {
      head.style.height = `0`
    }
  }
  ngOnInit(): void {
    let head = document.getElementById("heade")
    head.style.height = document.documentElement.scrollTop < 700 ? `${700 - document.documentElement.scrollTop}px` : '0'

    let h = []
    let objs = document.getElementsByClassName('parallax')[0]
    // for (let i = 0; i < objs.length; i++) {
    //   h.push(objs[i]);
    // }
    new simpleParallax(objs)
  }

  contactSend() {
    console.log(this.contactForm.value);
    this.contact.postMessage(this.contactForm.value).then((resp) => {
      console.log(resp);
    },
      (err) => {
        console.log('err', err)
      })
  }
}
