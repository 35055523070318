<div id="heade"></div>
<div class="first">
    <!-- <div class="header centrado">
        <img src="/assets/images/header.png" alt="header-photo" id="header" class="">
    </div> -->
    <div class="data">
        <h1 class="title">
            {{headerText}}
        </h1>
        <div class="blue">
            <h2 class="m-0" [routerLink]="['/conoce']" style="cursor: pointer;">ACPM EXPRESS</h2>
        </div>
        <div class="wa">
            Comunicate con uno de nuestros asesores
            <a href="https://wa.me/message/FZS4AUQLZBWQE1" target="_blank">
                <img src="/assets/icons/Whatsapp.svg" alt="whatsapp">
            </a>
        </div>

    </div>
</div>
<div class="second">
    <div class="band">
        <h2>Ventajas de Nuestro Servicio</h2>
    </div>
    <div class="info row no-gutters">
        <div class="item col-xl col-4" *ngFor="let item of second1">
            <div class="of">
                <img [src]="'/assets/'+item.img" alt="icon" class="icon centrado-h">
                <h3 class="desc">
                    {{item.text}}
                </h3>
            </div>
        </div>
        <div class="item col-xl col-4" *ngFor="let item of second2">
            <div class="of">
                <img [src]="'/assets/'+item.img" alt="icon" class="icon centrado-h">
                <h3 class="desc">
                    {{item.text}}
                </h3>
            </div>
        </div>
    </div>
    <div class="band"></div>
</div>
<div class="seventh" style="margin-top:50px">
    <h2 class="title">ACPM EXPRESS</h2>
    <div class="img-cont"></div>
    <div class="desc">
        ACPM S.A.S pone al servicio de sus clientes su programa de ACPM EXPRESS, direccionado a la recepción, análisis
        y
        recolección de muestras, para de esta manera controlar y mejorar la eficiencia de la operación en la gestión de
        combustibles dando como resultado una mayor productividad.
    </div>
</div>

<div class="third">
    <h2 class="title">Nuestros Vehiculos</h2>
    <div class="show row">
        <div class="item col-xl col-6" *ngFor="let item of third">
            <div class="of">
                <img class="img centrado-h" [src]="'/assets/'+item.img" alt="vehiculo">
                <h3 class=desc> {{item.text}}</h3>
            </div>
        </div>
    </div>
</div>
<!-- <div class="fourth">
    <h2 class="title">Transporte de Hidrocarburos</h2>
    <div class="img-cont"></div>
    <div class="show row">
        <div class="item col-sm col-6" *ngFor="let item of fourth1">
            <div class="flip">
                <img class="img centrado-h" [src]="'/assets/'+item.img" alt="vehiculo">
                <h3 class=desc> {{item.text}}</h3>
                <div class="more">
                    {{item.more}}
                </div>
            </div>
        </div>
        <div class="item col-sm col-12" *ngFor="let item of fourth2">
            <div class="flip">
                <img class="img centrado-h" [src]="'/assets/'+item.img" alt="vehiculo">
                <h3 class=desc> {{item.text}}</h3>
                <div class="more">
                    {{item.more}}
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="fifth">
    <h2 class="title">
        Plantas Disponibles
    </h2>
    <div class="background">
        <img src="/assets/images/Mapa.png" alt="map" class="map">
    </div>
</div>
<div class="band"></div>

<div class="eighth">
    <h2 class="title">Contactenos</h2>
    <form [formGroup]="contactForm">
        <div class="row">
            <div class="col-md-4 col-12">
                <label class="inputBox">
                    <input type="text" formControlName="name">
                    <span class="label">Nombre</span>
                </label>
            </div>
            <div class="col-md-4 col-12">
                <label class="inputBox">
                    <input type="email" formControlName="email">
                    <span class="label">E-mail</span>
                </label>
            </div>
            <div class="col-md-4 col-12">
                <label class="inputBox">
                    <input type="number" formControlName="phone">
                    <span class="label">Teléfono</span>
                </label>
            </div>
            <div class="col-12">
                <label class="inputBox">
                    <textarea formControlName="msm" style="height:100px"></textarea>
                    <span class="label">Mensaje</span>
                </label>
            </div>
        </div>
    </form>
    <button class="btn btn-primary" [disabled]="contactForm.invalid" (click)=contactSend()> Enviar</button>
</div>