import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conoce',
  templateUrl: './conoce.component.html',
  styleUrls: ['./conoce.component.scss']
})
export class ConoceComponent implements OnInit {

  third3 = [
    {
      img: "about/third/combustible.svg",
      text: "Manejo Seguro de combustibles"
    },
    {
      img: "about/third/extintores.svg",
      text: "Manejo de Extintores"
    },
    {
      img: "home/fourth/lavado de tanque.svg",
      text: "Manejo de producto e inventarios"
    },
    {
      img: "about/third/analisis.svg",
      text: "Análisis de combustible en laboratorio"
    },
  ]
  third4 = [
    {
      img: "about/third/emergencias.svg",
      text: "Manejo de Emergencias (Derrames e Incendios)"
    },
    {
      img: "about/third/combustion.svg",
      text: "Motores de combustión interna Diesel - Gas"
    }
  ]
  third5 = [
    {
      img: "about/third/medicion.svg",
      text: "Sistemas de medición del combustible"
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
