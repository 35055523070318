<div class="first">
    <div class="band">
        <h2>ACPM EXPRESS</h2>
    </div>
    <div class="img"></div>
    <div class="band"></div>
    <div class="desc">
        Responsabilidad, calidad y puntualidad son algunas de las premisas que nos han consagrado como uno de los
        mejores comercializadores del mercado.
        Ofrecemos un servicio que se encuentra estructurado dentro de los más altos estándares prestacionales y de
        gestión humana.
        <b>ACPM S.A.S.</b> destaca por su sentido de pertenencia en cada vínculo contractual, procurando relaciones
        mercantiles que perduren en el tiempo y que brinden plenas garantías de cumplimiento.

    </div>
</div>
<div class="section">
    <h2 class="title">Soporte Tecnico</h2>
    <div class="desc">
        Dentro del concepto de mejoramiento continuo en la prestación de servicios a los clientes, ACPM SAS coloca a
        disposición de sus clientes el recurso humano para soporte y asistencia técnica con el fin de realizar las
        visitas técnico-comerciales de manera periódica y de acuerdo con un cronograma definido por ambas partes.
    </div>
</div>
<div class="section">
    <h2 class="title">Equipos en Comodato</h2>
    <div class="desc">
        Como herramienta de trabajo tendremos la posibilidad de entregar a nuestros clientes equipos en comodato tanto
        de almacenamiento, filtrado y suministro de acuerdo con sus necesidades.
    </div>
    <img class="centrado-h" height="200" src="/assets/images/Tanque.png" alt="">
</div>
<div class="section">
    <h2 class="title">Calibracion de Equipos</h2>
    <div class="desc">
        La calibración de quipos es muy importante para garantizar el funcionamiento óptimo del mismo. El objetivo de
        este proceso es asegurarse que el volumen de combustible que es despachado a los clientes sea consistente y
        preciso.
    </div>

    <img class="centrado-h" height="200" src="/assets/images/seraphin.png" alt="">
</div>

<div class="section">
    <h2 class="title">Programas de Capacitación</h2>
    <div class="desc">
        Nuestro departamento técnico y comercial podrá brindar capacitación al personal de mantenimiento
        en el área de combustible, mediante el uso de modernas ayudas audiovisuales y
        un amplio programa de conferencias adaptadas a las necesidades especificas de cada cliente,
        entre otros podemos mencionar:
    </div>
    <br>
    <ul>
        <li *ngFor="let item of third3">
            {{item.text}}
        </li>
        <li *ngFor="let item of third4">
            {{item.text}}
        </li>
        <li *ngFor="let item of third5">
            {{item.text}}
        </li>
    </ul>
</div>
<!-- <div class="third row no-gutters">
    <div class="item col-sm-3 col-6" *ngFor="let item of third3">
        <img [src]="'/assets/'+item.img" alt="icon" class="icon centrado-h">
        <h3 class="desc">
            {{item.text}}
        </h3>
    </div>
    <div class="item col-sm-4 col-6" *ngFor="let item of third4">
        <img [src]="'/assets/'+item.img" alt="icon" class="icon centrado-h">
        <h3 class="desc">
            {{item.text}}
        </h3>
    </div>

    <div class="item col-sm-4 col-12" *ngFor="let item of third5">
        <img [src]="'/assets/'+item.img" alt="icon" class="icon centrado-h">
        <h3 class="desc">
            {{item.text}}
        </h3>
    </div>
</div> -->