import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { api } from "../../environments/apikeys"

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) {
    emailjs.init(api.User_ID)
  }

  postMessage(input) {
    return emailjs.send(api.email, api.template, input, api.User_ID)
  }

}
